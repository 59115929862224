.profile-page {
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.profile-card {
  border: none;
  border-radius: 15px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.profile-icon {
  font-size: 100px;
  color: #004080; /* Bleu personnalisé pour l'icône de profil */
}

.profile-card .card-title {
  font-size: 1.8rem;
  color: #004080; /* Bleu personnalisé pour le titre */
}

.profile-card .card-text {
  font-size: 1.1rem;
  color: #555;
}

.profile-divider {
  border-top: 1px solid #004080; /* Bleu personnalisé pour le séparateur */
}

.profile-card hr {
  border-top: 1px solid #ddd;
}

.order-list .order-item {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.order-list .order-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.order-item strong {
  font-size: 1.2rem;
  color: #004080; /* Bleu personnalisé pour les titres des commandes */
}

.order-item .badge {
  font-size: 1rem;
  border-radius: 8px;
  background-color: #004080; /* Bleu personnalisé pour les badges */
}

.text-primary-custom {
  color: #004080 !important; /* Bleu personnalisé pour les titres */
}

.profile-card .btn {
  font-size: 1rem;
  border-radius: 50px;
  padding: 10px 20px;
}
