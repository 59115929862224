.navbar-collapse {
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    margin-left: 55%;
    padding-left: 5%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.navbar {
    position: relative;
}

.navbar-nav {
    display: flex;
    flex-direction: row !important;
    margin-right: 5%;
}

.navbar::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #004AAD;
}

.navbar-nav .dropdown-menu {
    position: absolute !important;
    background-color: #B5CAE6;
    box-shadow: 5px 5px 7px rgb(33 37 41 / 19%);
    border: 0;
}

.dropdown-item {
    color: #004aad !important;
    font-weight: 500 !important;
}

.dropdown-item :hover {
    background-color: #004aad;
    color: #B5CAE6 !important;
}

.dropdown-item:active {
    background-color: #B5CAE6;
    color: #004aad !important;
}