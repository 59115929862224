.footer {
    background-color: #2c3e50;
    color: #fff;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  
  .footer h5 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #ecf0f1;
  }
  
  .footer p,
  .footer ul {
    font-size: 0.9rem;
    color: #bdc3c7;
  }
  
  .footer ul {
    padding: 0;
    list-style: none;
  }
  
  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer ul li a {
    color: #bdc3c7;
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    color: #ffffff;
  }
  
  .social-icons a {
    font-size: 1.5rem;
    margin: 0 10px;
    color: #bdc3c7;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #2980b9;
  }
  
  .footer span {
    font-size: 0.85rem;
    color: #bdc3c7;
  }
  