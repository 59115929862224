.terms-wrapper {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.terms-header {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

h1 {
  font-size: 2rem;
  color: #333333;
  margin-bottom: 0;
}

.last-updated {
  font-size: 0.9rem;
  color: #777777;
}

.terms-content h2 {
  font-size: 1.5rem;
  color: #333333;
  margin-top: 20px;
}

.terms-content p {
  font-size: 1rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.terms-content p strong {
  color: #000000;
}
