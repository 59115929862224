.product-card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .product-link {
    text-decoration: none;
    color: inherit;
  }
  
  .product-img-wrapper {
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .product-img {
    height: 100%;
    width: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .product-card:hover .product-img {
    transform: scale(1.1);
  }
  
  .catalogue-container {
    padding: 20px;
  }
  
  .product-col {
    margin-bottom: 30px;
  }
  
  .form-control {
    font-size: 1rem;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  button {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 8px;
  }
  
  button.me-2 {
    margin-right: 10px;
  }
  
  h1 {
    font-weight: bold;
  }
  