@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.product-details-page {
  font-family: 'Montserrat', sans-serif;
  color: #333;
}

.product-image-wrapper {
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.product-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.product-image-wrapper:hover .product-image {
  transform: scale(1.05);
}

.product-details-wrapper {
  padding: 30px;
  background-color: #fafafa;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.product-name {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #004aad;
}

.product-description {
  font-size: 1.2rem;
  margin-bottom: 25px;
  line-height: 1.7;
  color: #555;
}

.product-price {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #2d89ef;
}

.add-to-cart-btn {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #004aad;
  border: none;
  color: #fff;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #003a8c;
}
