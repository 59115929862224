.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .cookie-consent p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  .cookie-link {
    color: #3498db;
    text-decoration: underline;
    margin-left: 5px;
  }
  
  .cookie-link:hover {
    color: #2980b9;
  }
  
  .accept-button {
    background-color: #2980b9;
    color: #ecf0f1;
    border: none;
    padding: 8px 15px;
    font-size: 0.9rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .accept-button:hover {
    background-color: #1d6fa5;
  }
  