.product-card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    overflow: hidden; /* Ensure that the image doesn't overflow */
  }
  
  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .product-link {
    text-decoration: none;
    color: inherit;
  }
  
  .product-img-wrapper {
    height: 250px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .product-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire area */
    transition: transform 0.3s ease-in-out;
  }
  
  .product-card:hover .product-img {
    transform: scale(1.1);
  }
  