/* PaymentPage.css */
.payment-page {
    background-color: #f8f9fa;
    padding: 2rem 0;
  }
  
  .payment-page h2 {
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  
  .payment-form {
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-top: 2rem;
  }
  
  .payment-form form {
    width: 100%;
  }
  
  .button-primary {
    background-color: #004aad;
    border: none;
    color: white;
    font-size: 1.2rem;
    padding: 0.75rem 1.5rem;
    transition: background-color 0.3s ease-in-out;
  }
  
  .button-primary:hover {
    background-color: #003a8c;
  }
  